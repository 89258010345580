import { IPurchaseHistoryRequest } from 'types/purchaseHistoryTypes'
import { ISearchVehiclesRequest } from 'types/vehicleTypes'
import { DateTime } from 'luxon'
import { IAuctionHistoryRequest, ISortBy, SortOrder } from 'types/baseTypes'
export const BMW_VENDOR_CODE = 'BMWUS001'
export const NISSAN_VENDOR_CODE = 'NISUS001'

export const PageSizeOptions = [25, 50, 100]
export const RECORDS_PER_PAGE_TEXT = 'Records per page:'
export const DEFAULT_PAGE_SIZE = 25
export const DEFAULT_BIG_PAGE_SIZE = 50
export const DEFAULT_HUGE_PAGE_SIZE = 100
export const DEFAULT_SMALL_PAGE_SIZE = 5
export const CONTINUE = 'Continue'
export const SUBMIT = 'Submit'
export const BMW_TEXT = 'BMW'
export const MEDIUM_SLIDER_ITEM_SIZE = 4
export const BIG_SLIDER_ITEM_SIZE = 8
export const HUGE_SLIDER_ITEM_SIZE = 12
export const VEHICLE_PROPERTIES_TO_HIDE = ['DD_CONDITION', 'MMR', 'VEHICLE_LOCATION', 'SALE_CHANNEL']
export const DEFAULT_ERROR_MESSAGE = 'An error has occurred. Please try again.'
export const PAGINATION_ITEM_SIZE = 10
export const CSRF_HEADER = '__RequestVerificationToken'
export const CSRF_TOKEN = 'RequestVerificationToken'
export const TERM_AND_CONDITION = 'Terms And Conditions'
export const FAQ = 'FAQs'
export const FRAME_DAMAGE_LABEL = '<b>Frame Damage: </b>'

export const CHARGEABLE_DAMAGE = 'ChargeableDamage'
export const NON_CHARGEABLE_DAMAGE = 'NonChargeableDamage'
export const UNKNOWN_CHARGEABLE_DAMAGE = 'UnknownChargeableDamage'
export const DISCLAIMER_TEXT = 'Disclaimer: No Issues = Tread 4/32 or greater'
export const STRUCTURE_DAMAGE_FILTERS = ['hasStructuralDamage', 'hasAsIs', 'hasTRA']

// Initial query payload for the SearchVehicle request
export const AVAILABLE_INVENTORY_INIT_QUERY: ISearchVehiclesRequest = {
  PageNumber: 0,
  PageSize: DEFAULT_PAGE_SIZE,
  QuickSearch: false,
  ReturnVehicles: false,
  ReturnZeroCountFilters: false,
  Ids: [],
  FilterOptions: {
    IsReserveMetVehicles: false,
    IsRecentlyAddedVehicles: false
  },
  SearchQuery: {
    ReturnOnlyVehicles: false,
    SearchFilters: [],
    SortBy: [],
    StatusIds: []
  }
}

export const AUCTION_HISTORY_INIT_QUERY: IAuctionHistoryRequest = {
  FromDate: DateTime.now().minus({ month: 1 }).toJSDate(),
  ToDate: DateTime.now().toJSDate(),
  PageNumber: 0,
  PageSize: DEFAULT_PAGE_SIZE,
  SortBy: {
    Name: 'Vehicle Description - ↑',
    Value: 'TITLE ASC',
    DBFieldName: null,
    SortOrder: SortOrder.ASC
  },
  Registration: null,
  VIN: null
}
export const autionHistorySortOptions: ISortBy[] = [
  {
    Name: 'Vehicle Description - ↑',
    Value: 'TITLE ASC',
    SortOrder: SortOrder.ASC,
    DBFieldName: 'Description'
  },
  {
    Name: 'Vehicle Description - ↓',
    Value: 'TITLE DESC',
    SortOrder: SortOrder.DESC,
    DBFieldName: 'Description'
  },
  {
    Name: 'VIN - ↑',
    Value: 'VIN ASC',
    SortOrder: SortOrder.ASC,
    DBFieldName: 'VIN'
  },
  {
    Name: 'VIN - ↓',
    Value: 'VIN DESC',
    SortOrder: SortOrder.DESC,
    DBFieldName: 'VIN'
  },
  {
    Name: 'My Highest Bid - ↑',
    Value: 'USERHEIGHESTBID ASC',
    SortOrder: SortOrder.ASC,
    DBFieldName: 'USERHEIGHESTBID'
  },
  {
    Name: 'My Highest Bid - ↓',
    Value: 'USERHEIGHESTBID DESC',
    SortOrder: SortOrder.DESC,
    DBFieldName: 'USERHEIGHESTBID'
  },
  {
    Name: 'Status - ↑',
    Value: 'BIDHISTORYSTATUS ASC',
    SortOrder: SortOrder.ASC,
    DBFieldName: 'Date'
  },
  {
    Name: 'Status - ↓',
    Value: 'BIDHISTORYSTATUS DESC',
    SortOrder: SortOrder.DESC,
    DBFieldName: 'Date'
  },
  {
    Name: 'Sales Channel Type - ↑',
    Value: 'SALECHANNELTYPE ASC',
    SortOrder: SortOrder.ASC,
    DBFieldName: 'Payment Method'
  },
  {
    Name: 'Sales Channel Type - ↓',
    Value: 'SALECHANNELTYPE DESC',
    SortOrder: SortOrder.DESC,
    DBFieldName: 'Payment Method'
  },
  {
    Name: 'Date - ↑',
    Value: 'AUCTIONENDDATE ASC',
    SortOrder: SortOrder.ASC,
    DBFieldName: 'AUCTIONENDDATE'
  },
  {
    Name: 'Date - ↓',
    Value: 'AUCTIONENDDATE DESC',
    SortOrder: SortOrder.DESC,
    DBFieldName: 'AUCTIONENDDATE'
  }
]
export const PURCHASE_HISTORY_INIT_QUERY: IPurchaseHistoryRequest = {
  FromDate: DateTime.now().minus({ month: 1 }).toJSDate(),
  ToDate: DateTime.now().toJSDate(),
  PageNumber: 0,
  Vin: null,
  PageSize: DEFAULT_PAGE_SIZE,
  SortBy: {
    Name: 'Purchase Date - ↓ ',
    Value: 'PURCHASEDATE DESC',
    DBFieldName: null,
    SortOrder: SortOrder.DESC
  }
}

export const SRP_INIT_QUERY: ISearchVehiclesRequest = {
  ...AVAILABLE_INVENTORY_INIT_QUERY,
  ReturnVehicles: true,
  SearchQuery: {
    ...AVAILABLE_INVENTORY_INIT_QUERY.SearchQuery,
    ReturnOnlyVehicles: true
  }
}

export const SRP_FILTERS_QUERY: Omit<typeof AVAILABLE_INVENTORY_INIT_QUERY, 'FilterOptions'> = {
  ...AVAILABLE_INVENTORY_INIT_QUERY
}

export const purchaseHistorySortOptions: ISortBy[] = [
  {
    Name: 'Vehicle Description - ↑',
    Value: 'TITLE ASC',
    SortOrder: SortOrder.ASC,
    DBFieldName: 'Description'
  },
  {
    Name: 'Vehicle Description - ↓',
    Value: 'TITLE DESC',
    SortOrder: SortOrder.DESC,
    DBFieldName: 'Description'
  },
  {
    Name: 'VIN - ↑',
    Value: 'VIN ASC',
    SortOrder: SortOrder.ASC,
    DBFieldName: 'VIN'
  },
  {
    Name: 'VIN - ↓',
    Value: 'VIN DESC',
    SortOrder: SortOrder.DESC,
    DBFieldName: 'VIN'
  },
  {
    Name: 'Total Price - ↑',
    Value: 'TOTALPRICE ASC',
    SortOrder: SortOrder.ASC,
    DBFieldName: 'Total Cost'
  },
  {
    Name: 'Total Price - ↓',
    Value: 'TOTALPRICE DESC',
    SortOrder: SortOrder.DESC,
    DBFieldName: 'Total Cost'
  },
  {
    Name: 'Price - ↑',
    Value: 'PREADJUSTMENTPRICE ASC',
    SortOrder: SortOrder.ASC,
    DBFieldName: 'Price'
  },
  {
    Name: 'Price - ↓',
    Value: 'PREADJUSTMENTPRICE DESC',
    SortOrder: SortOrder.DESC,
    DBFieldName: 'Price'
  },
  {
    Name: 'Purchase Date - ↑',
    Value: 'PURCHASEDATE ASC',
    SortOrder: SortOrder.ASC,
    DBFieldName: 'Date'
  },
  {
    Name: 'Purchase Date - ↓',
    Value: 'PURCHASEDATE DESC',
    SortOrder: SortOrder.DESC,
    DBFieldName: 'Date'
  },
  {
    Name: 'Payment Method - ↑',
    Value: 'PAYMENTMETHOD ASC',
    SortOrder: SortOrder.ASC,
    DBFieldName: 'Payment Method'
  },
  {
    Name: 'Payment Method - ↓',
    Value: 'PAYMENTMETHOD DESC',
    SortOrder: SortOrder.DESC,
    DBFieldName: 'Payment Method'
  }
]

export const MODEL_GROUP_MAPPER: { DisplayText: string; Value: string }[] = [
  { DisplayText: 'Hybrids', Value: 'Hybrid' }
]

export const AVAILABLE_INVENTORY_MAPPER: { DisplayText: string; Value: string }[] = [
  { DisplayText: 'SAVs', Value: 'SAV,SPORTS ACTIVITY VEHICLE,SPORT UTILITY VEHICLE' },
  { DisplayText: 'Sedan', Value: 'SEDAN,Sedan' },
  { DisplayText: 'Convertible', Value: 'CONVERTIBLE,Convertible' },
  { DisplayText: 'Coupe', Value: 'COUPE,Coupe' },

  { DisplayText: 'Fully Electric', Value: 'LIST_OF_ELECTRIC_FUEL_TYPES' },
  { DisplayText: 'Hybrids', Value: 'LIST_OF_HYBRID_FUEL_TYPES' },

  { DisplayText: 'BodyStyle', Value: 'Body Style' },
  { DisplayText: 'FuelType', Value: 'Fuel Type' },
  { DisplayText: 'AdditionalBodyStyle', Value: 'Additional Body Style' }
]

export const AVAILABLE_INVENTORY_HEADING: Record<string, string[]> = {
  BodyStyle: ['Sedan', 'Coupe', 'SAVs', 'Convertible'],
  AdditionalBodyStyle: ['Convertible'],
  FuelType: ['Fully Electric', 'Hybrids'],
  Make: ['BMW', 'MINI', 'Rolls-Royce']
}

export const GENERIC_NO_VEHICLE_AVAILABLE_MESSAGE = 'No vehicles available.'
export const NO_NEW_INVENTORY_MESSAGE = 'No new inventory.'

export const SessionStorageKey = {
  PURCHASE_HISTORY_SEARCH_FILTER: 'PurchaseHistoryFilter',
  SEARCH_FILTERS: 'SearchFilters',
  SEARCH_DESCRIPTION: 'SearchDescription',
  SEARCH_ID: 'SearchID',
  QUERY_FILTERS: 'QueryFilters',
  PAGE_SELECTED: 'PageSelected',
  SESSION_SORT: 'SessionSort',
  PAGE_SIZE: 'PageSize',
  VEHICLE_INSTANCES: 'vehicleInstances',
  FILTER_OPTION: 'FilterOptions',
  LIST_BREADCRUMB: 'breadCrumb_Listing',
  DETAIL_BREADCRUMB: 'breadCrumb_Detail',
  SIGNAL_R_CONNECTION_TRY_RECOUNT: 'signalRConnectionRetryCount',
  USER_LOCATION: 'UserLocation',
  SCROLL_TO_VIN: 'ScrollToVin',
  VIEW_VEHICLE_LIST: 'ViewVehicleMode'
}

export const ImageType = {
  DAMAGE_IMAGE: 'Damage Image'
}

export const DefaultFilterOptions = {
  IsReserveMetVehicles: false,
  IsRecentlyAddedVehicles: false
}

export const zIndex = {
  elevated: 10,
  floatingControls: 2, // This layer stays above Bootstrap custom controls and carousel navigators
  pageHeader: 3,
  menu: 20,
  stickyHeader: 1020,
  stickyAlert: 1021,
  modal: 1050,
  navbarMobile: 1300,
  loader: 1400
  // carousel-indicators: 15
} as const

export const VehicleStatuses = {
  OnSale: 36,
  SoldNotFunded: 4,
  SoldAndFunded: 5,
  TransactionComplete: 42
}
export const VehicleOfferStatuses = {
  OfferCountered: 3
}

export const VehicleOffers = {
  AWAITING: 1
}

export const DashboardGrounding = {
  PENDING_OFFERS: 0,
  GROUNDING_EXPIRING_SOON: 1,
  GROUNDING_EXPIRING_TODAY: 2,
  GROUNDING_OVERDUE: 3,
  GROUNDING_AWAITING_PRICING: 4,
  GROUNDING_AWAITING_INSPECTION: 5
}

export const MenuExclusionList = [{ name: 'RDP_MyAccount', childrens: ['RDP_MyProfile'] }]
export const FooterMenuExclusionList = ['RDP_ContactUs']

export const vendorImages = {
  leftLogo: '/content/img/bmw/logo-left-white.png',
  rightLogo: '/content/img/bmw/logo-right-white.png',
  leftLogoDark: '/content/img/bmw/logo-left.png',
  rightLogoDark: '/content/img/bmw/logo-right.png'
}

export const enum BaseVehicleSearchCriteriaColumn {
  Make = 1,
  Model = 3,
  VIN = 6,
  ModelYear = 79,
  Proximity = 1016,
  Promotion = 1027,
  NewInventory = 1028,
  DecreasedPrice = 1029,
  DisclosurePresent = 1030,
  OdometerCustomRange = 1032,
  VehicleConditionCode = 1033,
  DriveTrain = 1019,
  HasStructuralDamage = 1044,
  HasAsIs = 1045,
  HasTRA = 1046
}

export const MIN_MATCH_CHAR_LENGTH = 2

export const DefaultSortBy = {
  // Unset the name by default, so that we can query
  // and using the name returned from the API
  //
  // This will prevent hardcoded name not match with user's language pref
  Name: 'Vehicle Details - ↑',
  Value: 'Title ASC',
  SortOrder: SortOrder.ASC,
  DBFieldName: 'Title'
}

export const carfaxIcon = {
  carfaxTitleLogo: '/content/img/partners/carfax-title-logo.png',
  carfaxBaseUrl: '/content/img/partners/'
}

export const BUY_VEHICLE_NO_ACTIVITY = {
  WATCH_LIST: 'Once you add vehicles to your watch list, they will appear here.',
  CURRENT_BIDS: 'Once you place bids on vehicles, they will appear here.',
  SAVE_SEARCH: 'You do not have any searches currently saved.',
  SEARCH_RESULTS: 'We were not able to find any vehicles that matched your criteria.'
}

export const exportViews = {
  Excel: 1,
  PDF: 2
}
export const SELECT_DISPLAY_LIMIT = 3
export const SelfArrangedTransportProviderId = 3

export enum BidAmountTypeOptions {
  CurrentBidAmount = 'CurrentBidAmount',
  UpdateBidAmount = 'UpdateBidAmount'
}

export const BuyItNowPrice = 99999

export const enum VehicleDetailsView {
  List = 'list',
  Grid = 'grid'
}

export const EnterKeyCharCode = 13

export const enum TyreStatus {
  GOOD = 'good',
  NORMAL = 'normal',
  BAD = 'bad'
}
export const enum Tyres {
  FRONT_LEFT_TIRE,
  FRONT_RIGHT_TIRE,
  REAR_LEFT_TIRE,
  REAR_RIGHT_TIRE,
  SPARE_TIRE
}

export const TyresDescriptionValue = {
  [Tyres.FRONT_LEFT_TIRE]: ['FRONT LEFT TIRE', 'LFRNT'],
  [Tyres.FRONT_RIGHT_TIRE]: ['FRONT RIGHT TIRE', 'RFRNT'],
  [Tyres.REAR_LEFT_TIRE]: ['REAR LEFT TIRE', 'LREAR'],
  [Tyres.REAR_RIGHT_TIRE]: ['REAR RIGHT TIRE', 'RREAR'],
  [Tyres.SPARE_TIRE]: ['SPARE TIRE', 'SPARE']
}

export const TireTreadDepthRange = [4, 6, 32]

export const enum WidgetConfigurationDetails {
  WIDGET_SPECIFICS = 'Widget_Specifics',
  WIDGET_EQUIOMENT = 'Widget_Equipment',
  WIDGET_DAMAGEUPSTREAM = 'Widget_DamageUpstream',
  WIDGET_DAMAGEGROUNDEDEXCLUSIVE = 'Widget_DamageGroundedExclusive',
  WIDGET_PROMOTIONS = 'Widget_Promotions',
  WIDGET_ANNOUNCEMENTS = 'Widget_Announcements',
  WIDGET_PACKAGES = 'Widget_Packages',
  WIDGET_HISTORY = 'Widget_History',
  WIDGET_CONDITION = 'Widget_Condition',
  WIDGET_DAMAGE = 'Widget_Damage',
  WIDGET_TIRE = 'Widget_Tire',
  WIDGET_ADDITIONAL = 'Widget_Additional',
  WIDGET_DISCLAIMER = 'Widget_Disclaimer'
}

export const DEFAULT_EXPAND_FILTERS_NUMBER = 10
export const fullScreenIcon = {
  zoomOutLogo: '/content/img/zoom_out.png',
  zoomInLogo: '/content/img/zoom_in.png'
}
export const READY_LOGISTIC_LINK = 'https://www.readylogistics.com'

export const SELECTED_SEARCH_FILTERS_LIMIT = 5

export const PROXIMITY_FILTERS = [99999, 25, 50, 100, 200, 500, 1000]

export const enum RangeFieldIds {
  PROXIMITY_FIELD_ID = 1016,
  ODOMETERCUSTOMRANGE_FIELD_ID = 1032,
  VEHICLECONDITONCODE_FIELD_ID = 1033
}

export const RANGE_FIELD_ID = [
  RangeFieldIds.ODOMETERCUSTOMRANGE_FIELD_ID,
  RangeFieldIds.PROXIMITY_FIELD_ID,
  RangeFieldIds.VEHICLECONDITONCODE_FIELD_ID
]
